import { Link, Typography } from "@mui/material"
import styled from "styled-components"

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const StyledSpan = styled.span`
  color: coral;
`
const StyledLink = styled(Link)`
  &: hover {
    color: coral;
    text-decoration: none;
  }
`

const InappropriateContent = (props) => {
  let contentName

  if (props.movie) {
    contentName = props?.movie.original_title || props?.movie.title || props?.movie.name
  } else if (props.show) {
    contentName = props?.show.original_title || props?.show.title || props?.show.name
  } else {
    contentName = props?.actor.name + "'s page"
  }

  return (
    <StyledDiv>
      <Typography variant="h5">
        Inappropriate Content detected, for your safety we can not show you <StyledSpan>{contentName ?? 'this movie'}</StyledSpan> right now. If you like to be able to view these type of contents, please change your viewing options under <StyledLink sx={{ textDecoration: 'none' }} href='/settings'>settings</StyledLink>, thank you.
      </Typography>
    </StyledDiv>
  )
}

export default InappropriateContent