import React, { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Banner from '../../components/Banner'
import ActorOverview from './actor-overview'
import { useTitle } from '../../hooks/useTitle'
import actorRoutes from '../../client/actor-client'
import { CircularProgress, Card, Skeleton } from '@mui/material'
import { StyledCircularProgressDiv, StyledDivForMoviesNShowPages, StyledRowCardMedia } from '../../utils/styled'
import { InappropriateContent } from '../../components'

function ActorPage() {
  const [actor, setActor] = useState({})
  const [opacity, setOpacity] = useState(0)
  const [loading, setLoading] = useState(false)
  const [externalId, setExternalId] = useState()
  const [documentTitle, setDocumentTitle] = useTitle()
  const [movieCredits, setMovieCredits] = useState([])
  const [actorPictures, setActorPictures] = useState([])
  const [randomInt] = useState(Math.floor(Math.random() * 10))
  const { ActorId } = useParams()

  useEffect(() => {
    setTimeout(() => {
      setOpacity('1')
    }, 1000)
  }, [])

  useEffect(() => {
    document.title = documentTitle ?? 'Movieception'
  }, [documentTitle])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const request = actorRoutes.getActor(ActorId)
      const requestImages = actorRoutes.getImages(ActorId)
      const requestCredits = actorRoutes.getCredits(ActorId)
      const requestExternalId = actorRoutes.getExternalIds(ActorId)
      Promise.all([request, requestImages, requestCredits, requestExternalId])
        .then(res => {
          if (!res) return
          setActor(res[0].data)
          setExternalId(res[3].data)
          setActorPictures(res[1].data.profiles)
          setMovieCredits(res[2].data.cast)
          setDocumentTitle(res[0].data?.name)
        })
        .catch(res => {
          console.log(res)
          setLoading(false)
        })
        .finally(() => setLoading(false))
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ActorId])

  const slicedPictures = actorPictures.slice(0, 10)

  movieCredits.sort((a, b) => (a.release_date > b.release_date ? -1 : b.release_date > a.release_date ? 1 : 0))

  return (
    <>
      {loading ? (
        <StyledCircularProgressDiv>
          <CircularProgress size={70} />
        </StyledCircularProgressDiv>
      ) : actor?.adult ? <InappropriateContent actor={actor} /> : (
        <div>
          {movieCredits[randomInt]?.backdrop_path ? (
            <Banner link={movieCredits[randomInt]?.backdrop_path} title={movieCredits[randomInt]?.title} />
          ) : (
            <Banner link={movieCredits[randomInt + 1]?.backdrop_path} title={movieCredits[randomInt + 1]?.title} />
          )}
          <StyledDivForMoviesNShowPages>
            <Card xs={{ maxWidth: 312, minHeight: 468 }}>
              {(!actor?.profile_path && opacity > 0) ? (
                <Skeleton variant="rectangular" width={312} height={468} />
              ) : (
                <StyledRowCardMedia
                  sx={[{ height: 468, width: 312, backgroundSize: 'contain', opacity: opacity, transition: 'opacity 1s ease-in-out' }]}
                  image={`https://image.tmdb.org/t/p/original/${actor?.profile_path}`}
                />
              )}
            </Card>
            <ActorOverview
              actor={actor}
              externalId={externalId}
              slicedPictures={slicedPictures}
            />
          </StyledDivForMoviesNShowPages>
        </div>
      )}
    </>
  )
}

export default ActorPage