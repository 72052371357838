import React, { useEffect, useState } from 'react'
import { CircularProgress, Card, Skeleton } from '@mui/material'
import { useParams } from 'react-router-dom'
import Banner from '../../components/Banner'
import { base_url } from '../../utils/helper'
import Credits from '../../components/Credits'
import Seasons from '../../components/Seasons'
import Trailer from '../../components/Trailer'
import { useTitle } from '../../hooks/useTitle'
import Overview from '../../components/Overview'
import showRoutes from '../../client/show-client'
import ExternalId from '../../components/ExternalId'
import Recommendations from '../../components/Recommendations'
import { StyledCircularProgressDiv, StyledDivForMoviesNShowPages, StyledRowCardMedia } from '../../utils/styled'
import { InappropriateContent } from '../../components'

function ShowPage({ user }) {
  const [show, setShow] = useState({})
  const [videos, setVideos] = useState()
  const [opacity, setOpacity] = useState(0)
  const [loading, setLoading] = useState(false)
  const [externalId, setExternalId] = useState()
  const [documentTitle, setDocumentTitle] = useTitle()
  const [trailerModal, setTrailerModal] = useState(false)
  const { ShowId } = useParams()

  useEffect(() => {
    setTimeout(() => {
      setOpacity('1')
    }, 1000)
  }, [])

  useEffect(() => {
    document.title = documentTitle ?? 'Movieception'
  }, [documentTitle])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const request = showRoutes.getShow(ShowId)
      const requestVideos = showRoutes.getShowVideos(ShowId)
      const requestExternalIds = showRoutes.getShowExternalId(ShowId)
      Promise.all([request, requestVideos, requestExternalIds])
        .then(res => {
          if (!res) return
          setShow(res[0].data)
          setVideos(res[1].data)
          setExternalId(res[2].data)
          setDocumentTitle(res[0].data?.original_title || res[0].data?.title || res[0].data?.name)
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    }
    if (ShowId) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ShowId])

  return (
    <div>
      {loading ? (
        <StyledCircularProgressDiv>
          <CircularProgress size={70} />
        </StyledCircularProgressDiv>
      ) : show?.adult ? <InappropriateContent show={show} /> : (
        <>
          <Banner link={show?.backdrop_path} />
          <StyledDivForMoviesNShowPages>
            <Card sx={{ maxWidth: 312, minHeight: 468 }}>
              {(!(show?.poster_path || show?.backdrop_path || show?.profile_path) && opacity > 0) ? (
                <Skeleton variant="rectangular" width={312} height={468} />
              ) : (
                <StyledRowCardMedia
                  sx={[{ height: 468, width: 312, backgroundSize: 'contain', opacity: opacity, transition: 'opacity 1s ease-in-out' }]}
                  image={
                    (show?.poster_path || show?.backdrop_path || show?.profile_path) &&
                    `${base_url}${show?.poster_path || show?.backdrop_path || show?.profile_path}`
                  }
                />
              )}
            </Card>
            <ExternalId link={show} externalId={externalId} />
            <Overview link={show} show={show} user={user} setTrailerModal={setTrailerModal} videos={videos} />
            <Seasons show={show} />
            <Credits show={show} />
            <Recommendations show={show} />
            <Trailer videos={videos} show={trailerModal} handleClose={() => setTrailerModal(false)} />
          </StyledDivForMoviesNShowPages>
        </>
      )}
    </div>
  )
}

export default ShowPage
