import React from 'react'
import styled from 'styled-components'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import RandomizerModal from '.'
import { Tooltip } from '@mui/material'

const StyledRandomizerBtnDiv = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 2rem 2rem 3.8rem 2rem;
  z-index: 10;
  background-color: rgb(116, 15, 15);
  padding: .8rem;
  border-radius: 15px;
  transition: 0.6s all ease-in-out;
  &: hover {
    cursor: pointer
  }
`

function RandomizerBtn({ randomizerModal, setRandomizerModal }) {
  return (
    <div>
      <Tooltip title='Randomizer!'>
        <StyledRandomizerBtnDiv onClick={() => setRandomizerModal(true)}>
          <ShuffleIcon onClick={() => setRandomizerModal(true)} />
        </StyledRandomizerBtnDiv>
      </Tooltip>
      <RandomizerModal openRandomizerModal={randomizerModal} setRandomizerModal={setRandomizerModal} />
    </div>
  )
}

export default RandomizerBtn
