import React, { useState } from 'react'
import Row from '../../components/Row'
import movieRoutes from '../../client/movie-client'

function Movies({ randomizerModal }) {
  document.title = 'Movies'
  const [currentPage, setCurrentPage] = useState(1)

  return (
    <div>
      <Row
        fetchUrl={movieRoutes.getPopular(currentPage)}
        title={'Popular Movies'}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        randomizerModal={randomizerModal}
      />
    </div>
  )
}

export default Movies
