import React, { useState, useEffect } from 'react'
import { Button, Modal, Typography } from '@mui/material'
import { StyledContainerForSearchModal } from '../../utils/styled'

const RandomizerModal = ({ openRandomizerModal, setRandomizerModal }) => {
  const [movieList, setMovieList] = useState()

  useEffect(() => {
    setMovieList(JSON.parse(localStorage.getItem('movieList')))
  }, [openRandomizerModal])

  const handleRandomize = () => {
    const randomMovieHrefIndex = Math.floor(Math.random() * movieList?.length)
    const randomMovieHref = movieList[randomMovieHrefIndex].href
    window.location.href = randomMovieHref
  }

  return (
    <Modal open={openRandomizerModal} onClose={() => setRandomizerModal(false)}>
      <StyledContainerForSearchModal maxWidth='md'>
        {movieList === null ? (
          <Typography variant='h4'>You need to add movies first</Typography>
        )
          : (
            <>
              <Typography style={{ borderBottom: '1px solid', marginBottom: '1rem', paddingBottom: '.5rem' }} variant='h4'>Choose a Random Movie!</Typography>
              <Typography variant='h6' style={{ color: 'coral' }}>
                {movieList?.map(m => m.title).join(' | ')}
              </Typography>
              <div style={{ marginTop: '1.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button style={{ marginRight: '.3rem' }} variant='outlined' disabled={movieList?.length < 1} color='error' onClick={() => {
                  setMovieList([])
                  localStorage.setItem('movieList', JSON.stringify([]))
                }} >
                  CLEAR
                </Button>
                <Button style={{ marginLeft: '.3rem' }} variant='outlined' disabled={movieList?.length < 3} onClick={() => handleRandomize()}>
                  Get a Random Movie!
                </Button>
              </div>
              {movieList?.length < 3 &&
                <Typography variant='body2' style={{ fontStyle: 'italic', marginTop: '.8rem' }}>
                  You need at least 3 movies
                </Typography>
              }
            </>
          )
        }
      </StyledContainerForSearchModal>
    </Modal>
  )
}

export default RandomizerModal