import React, { useState } from 'react'
import { Modal, Button, Typography, TextField, Grid, CircularProgress } from '@mui/material'
import { StyledContainerForSearchModal } from '../../utils/styled'
import { createBinder } from '../../hooks/createBinder'
import { useSnackbar } from 'notistack'
import APIClient from '../../client/API-client'

const ChangePasswordModal = ({ open, setOpen }) => {
  const [info, setInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const [oldPasswordError, setOldPasswordError] = useState('')
  const [newPasswordError, setNewPasswordError] = useState('')

  const bindValue = createBinder(info, setInfo)

  const { enqueueSnackbar } = useSnackbar()

  const handlePasswordReset = () => {
    if (info.newPassword !== info.newPasswordCheck) return setNewPasswordError('Password Doesn\'t match')
    setLoading(true)
    APIClient.changePassword({ oldPassword: info.oldPassword, newPassword: info.newPassword })
      .then(res => {
        if (res) {
          setLoading(false)
          enqueueSnackbar('Successfully updated password!', { variant: 'success' })
        }
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
        return setOldPasswordError('Wrong password!')
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <StyledContainerForSearchModal maxWidth='sm'>
          <Typography sx={{ pb: 3, mt: -5 }} variant='h5' component='h2'>Change Password</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Old Password'
                type='password'
                error={oldPasswordError && oldPasswordError}
                helperText={oldPasswordError && oldPasswordError}
                {...bindValue('oldPassword')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='New Password'
                type='password'
                error={newPasswordError && newPasswordError}
                helperText={newPasswordError && newPasswordError}
                {...bindValue('newPassword')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='New Password'
                type='password'
                error={newPasswordError && newPasswordError}
                helperText={newPasswordError && newPasswordError}
                {...bindValue('newPasswordCheck')}
              />
            </Grid>
          </Grid>
          {loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '1rem'
              }}
            >
              <CircularProgress size={40} />
            </div>
          ) : (
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color='error'
                  variant='outlined'
                  sx={{ color: 'red', mt: 2 }}
                  onClick={() => {
                    setInfo({})
                    setOldPasswordError('')
                    setNewPasswordError('')
                    setOpen(false)
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color='warning'
                  variant='outlined'
                  sx={{ color: 'coral', mt: 2 }}
                  onClick={() => {
                    setOpen(false)
                    handlePasswordReset()
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
        </StyledContainerForSearchModal>
      </Modal>
    </>
  )
}

export default ChangePasswordModal