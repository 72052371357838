import { InfoOutlined } from '@mui/icons-material'
import { Card, CardContent, CardHeader, Grid, TextField, Tooltip } from '@mui/material'
import React from 'react'

const Social = ({ bindValue }) => {
  return (
    <div>
      <Card sx={{ maxWidth: 345 }}>
        <CardHeader
          title={
            <>
              Socials
              <Tooltip sx={{ ml: .8, mb: .2 }} arrow title='Coming soon!'>
                {/* <Tooltip sx={{ ml: .8, mb: .2 }} arrow title='You can choose to display these information in your profile page for others to see by enabling Public from General Settings!'> */}
                <InfoOutlined />
              </Tooltip>
            </>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label='Website'
                type='url'
                name='website'
              // {...bindValue('website')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label='Facebook'
                type='text'
                name='facebook'
              // {...bindValue('facebook')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label='Instagram'
                type='text'
                name='instagram'
              // {...bindValue('instagram')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label='Twitter - X'
                type='text'
                name='twitter'
              // {...bindValue('twitter')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label='LinkedIn'
                type='text'
                name='linkedin'
              // {...bindValue('linkedin')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label='Github'
                type='text'
                name='github'
              // {...bindValue('github')}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default Social