const lastWatchedEpisode = (movie, title) => {
  if (title !== 'Watched TV Shows') return
  let lastWatched = []
  let latestDate = null

  movie?.seasons.forEach(season => {
    if (!season.last_watched) return
    const lastWatchedSeason = season.last_watched

    if (!latestDate || lastWatchedSeason[0].date > latestDate) {
      lastWatched = lastWatchedSeason
      latestDate = lastWatchedSeason[0].date
    }
  })

  return lastWatched
}

export default lastWatchedEpisode