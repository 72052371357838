import React, { useState, useEffect } from 'react'
import { useTitle } from '../../hooks/useTitle'
import { useParams } from 'react-router-dom'
import Banner from '../../components/Banner'
import { Card, CircularProgress, Skeleton } from '@mui/material'
import { StyledCircularProgressDiv, StyledDivForMoviesNShowPages, StyledRowCardMedia } from '../../utils/styled'
import { base_url } from '../../utils/helper'
import Overview from '../../components/Overview'
import Credits from '../../components/Credits'
import Recommendations from '../../components/Recommendations'
import ExternalId from '../../components/ExternalId'
import movieRoutes from '../../client/movie-client'
import Trailer from '../../components/Trailer'
import { InappropriateContent } from '../../components'
// import Buttons from '../../components/Buttons'
// import Trailer from '../../components/Trailer'
// import Reviews from '../../components/Reviews'

function MoviePage({ user }) {
    const { MovieId } = useParams()
    const [movie, setMovie] = useState({})
    const [videos, setVideos] = useState()
    const [opacity, setOpacity] = useState(0)
    const [loading, setLoading] = useState(false)
    const [externalId, setExternalId] = useState()
    const [documentTitle, setDocumentTitle] = useTitle()
    const [trailerModal, setTrailerModal] = useState(false)
    //   const [reviewModal, setReviewModal] = useState(false)
    //   const [hasReviews, setHasReviews] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setOpacity('1')
        }, 1000)
    }, [])

    useEffect(() => {
        document.title = documentTitle ?? 'Movieception'
    }, [documentTitle])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const request = movieRoutes.getMovie(MovieId)
            const requestVideos = movieRoutes.getMovieVideos(MovieId)
            const requestExternalIds = movieRoutes.getMovieExternalId(MovieId)
            Promise.all([request, requestVideos, requestExternalIds])
                .then(res => {
                    if (!res) return
                    setMovie(res[0].data)
                    setVideos(res[1].data)
                    setExternalId(res[2].data)
                    setDocumentTitle(res[0].data?.original_title || res[0].data?.title || res[0].data?.name)
                })
                .catch(err => console.log(err))
                .finally(() => setLoading(false))
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loading ? (
                <StyledCircularProgressDiv>
                    <CircularProgress size={70} />
                </StyledCircularProgressDiv>
            ) : movie?.adult ? <InappropriateContent movie={movie} /> : (
                <>
                    <Banner link={movie?.backdrop_path} />
                    <StyledDivForMoviesNShowPages>
                        <Card sx={{ maxWidth: 312, minHeight: 468 }}>
                            {(!(movie?.poster_path || movie?.backdrop_path || movie?.profile_path) && opacity > 0) ? (
                                <Skeleton variant="rectangular" width={312} height={468} />
                            ) : (
                                <StyledRowCardMedia
                                    sx={[{ height: 468, width: 312, backgroundSize: 'contain', opacity: opacity, transition: 'opacity 1s ease-in-out' }]}
                                    image={
                                        (movie?.poster_path || movie?.backdrop_path || movie?.profile_path)
                                        && `${base_url}${movie?.poster_path || movie?.backdrop_path || movie?.profile_path}`
                                    }
                                />
                            )}
                        </Card>
                        <ExternalId link={movie} externalId={externalId} />
                        <Overview link={movie} movie={movie} user={user} setTrailerModal={setTrailerModal} videos={videos} />
                        <Credits movie={movie} />
                        <Recommendations movie={movie} />
                        <Trailer videos={videos} show={trailerModal} handleClose={() => setTrailerModal(false)} />
                    </StyledDivForMoviesNShowPages>
                </>
            )}
        </>
    )
}

export default MoviePage
