import { useContext, useState } from 'react'
import Fallback from './components/Fallback'
import MarvelMovies from './pages/Marvel/movies'
import MarvelShows from './pages/Marvel/shows'
import { StyledMainAppDiv } from './utils/styled'
import CssBaseline from '@mui/material/CssBaseline'
import { ErrorBoundary } from "react-error-boundary"
import { Route, Navigate, Routes } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Navbar, Collections, SearchResults, ScrollToTop, ScrollToBottom, Footer, RandomResults } from './components'
import { Home, Discover, MoviePage, ShowPage, Movies, Shows, Actors, ActorPage, SeasonPage, Profile, About, TermsOfService, PrivacyPolicy, Settings, User, Netflix } from './pages'
import './index.css'
import { MovieceptionContext } from './context/MovieceptionContext'
import RandomizerBtn from './components/RandomizerModal/randomizerBtn'

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(true)
  const [randomizerModal, setRandomizerModal] = useState(false)
  const {
    data: loggedInUser,
    status,
    isLoading,
    loginWithPopup,
    user,
    logout
  } = useContext(MovieceptionContext)

  // change dark - light mode via settings
  const theme = createTheme({
    palette: {
      mode: `${isDarkMode ? 'dark' : 'light'}`
    },
  })

  const isMovie = window.location.href.includes('movies')

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar
          user={user}
          status={status}
          logout={logout}
          isLoading={isLoading}
          loggedInUser={loggedInUser}
          loginWithPopup={loginWithPopup}
        />
        <ErrorBoundary FallbackComponent={Fallback}>
          <ScrollToTop />
          <ScrollToBottom user={loggedInUser} loggedInUserLoading={status} />
          {isMovie && <RandomizerBtn randomizerModal={randomizerModal} setRandomizerModal={setRandomizerModal} />}
          <StyledMainAppDiv>
            <Routes>
              <Route path="/home" element={<Home user={loggedInUser} />} />
              <Route path="/search" element={<SearchResults user={loggedInUser} />} />
              <Route path="/discover" element={<Discover user={loggedInUser} />} />
              <Route path="/movies" element={<Movies randomizerModal={randomizerModal} user={loggedInUser} />} />
              <Route path="/netflix" element={<Netflix user={loggedInUser} />} />
              <Route path="/movies/:MovieId/:MovieTitle" element={<MoviePage user={loggedInUser} />} />
              <Route path="/movies/collections/:CollectionId" element={<Collections user={loggedInUser} />} />
              <Route path="/movies/marvel" element={<MarvelMovies />} />
              <Route path="/shows/marvel" element={<MarvelShows />} />
              <Route path="/shows" element={<Shows user={loggedInUser} />} />
              <Route path="/shows/:ShowId/:ShowTitle" element={<ShowPage user={loggedInUser} />} />
              <Route path="/shows/:ShowId/season" element={<SeasonPage user={loggedInUser} />} />
              <Route path="/actors" element={<Actors />} />
              <Route path="/actors/:ActorId/:ActorName" element={<ActorPage user={loggedInUser} />} />
              <Route path="/profile" element={<Profile user={loggedInUser} />} />
              <Route path="/users/:Username" element={<User />} />
              <Route path="/settings" element={<Settings isLoading={isLoading} loggedInUser={loggedInUser} isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />} />
              <Route path="/about" element={<About />} />
              <Route path="/random" element={<RandomResults user={loggedInUser} />} />
              <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/legal/tos" element={<TermsOfService />} />
              <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
          </StyledMainAppDiv>
          <Footer />
        </ErrorBoundary>
      </ThemeProvider>
    </div>
  )
}

export default App
