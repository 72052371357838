import { Tooltip } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const StyledSpan = styled.span`
  color: #b6894e;
`

const EpisodeOverview = ({ episode }) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  }
  return (
    <div>
      <div>
        {
          episode.season_number === 0 ? (
            <div>
              {episode.season_number + 1}x{episode.episode_number} {episode.name}
            </div>
          ) : (
            <div>
              {episode.season_number}x{episode.episode_number} {episode.name}
            </div>
          )
        }
      </div>
      <div>
        <div>Release Date: <em><StyledSpan>{new Date(episode.air_date).toLocaleString('en-US', options)}</StyledSpan></em></div>
      </div>
      <Tooltip title={episode.overview}>
        <div className='truncate'>
          {episode.overview}
        </div>
      </Tooltip>
      <div>
        <i className='fas fa-star fa' />
        <span style={{ fontSize: '1.2rem', paddingLeft: '0.4rem' }}>{episode?.vote_average.toFixed(1)}</span>
        <span style={{ paddingLeft: '0.4rem', fontSize: '1.2rem' }}>/ 10</span>
      </div>
    </div>
  )
}

export default EpisodeOverview