import React from 'react'
import { Grid, Card, CardHeader, CardContent, TextField, Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

const PersonalInformations = ({ bindValue }) => {
  return (
    <div>
      <Card sx={{ maxWidth: 345 }}>
        <CardHeader
          title={
            <>
              Personal Information
              <Tooltip sx={{ ml: .8, mb: .2 }} arrow title='You can choose to display these information in your profile page for others to see by enabling Public from General Settings!'>
                <InfoOutlined />
              </Tooltip>
            </>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Name'
                type='text'
                name='name'
                {...bindValue('name')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='Email'
                type='email'
                name='email'
                {...bindValue('email')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type='date'
                name='dob'
                {...bindValue('dob')}
                helperText='Date Of Birth'
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default PersonalInformations