const isEvent = e => {
  return e && typeof e === 'object' && !Array.isArray(e) && e.target;
};

const determineValue = e => {
  if (isEvent(e)) {
    if (e.target.type === 'checkbox') {
      return e.target.checked;
    }

    return e.target.value;
  }
};

const extractStateValue = (state, name) => {
  if (state === null || state === undefined) {
    return '';
  }

  if (name === null || name === undefined) {
    return state;
  }

  const parts = name.split('.');
  let currentObject = state;

  for (let i = 0; i < parts.length; i++) {
    currentObject = currentObject[parts[i]];

    if (currentObject === null || currentObject === undefined) {
      return '';
    }
  }

  return currentObject;
};

const createUpdatedState = (prev, name, value) => {
  if (name === null || name === undefined) {
    return value;
  }

  const updated = { ...prev };

  const parts = name.split('.');
  let currentObject = updated;

  for (let i = 0; i < parts.length - 1; i++) {
    currentObject[parts[i]] = { ...currentObject[parts[i]] }
    // ensure immutability

    currentObject = currentObject[parts[i]];
  }

  currentObject[parts[parts.length - 1]] = value;
  return updated;
};

// returns a function that creates an object with two fields to a field within the state
// Ex:
// const bindValue = createBinder(state, setState);
// <TextField {...bindValue('name)} />

export const createBinder = (state, setState) => {
  return name => {
    return {
      value: extractStateValue(state, name),
      onChange: e => {
        const value = determineValue(e);
        setState(prev => createUpdatedState(prev, name, value));
      }
    };
  };
};

// exports.createBinder = createBinder;
