import React, { useState } from 'react'
import ChangePasswordModal from './changePasswordModal'
import { Grid, Card, CardHeader, CardContent, TextField, FormControlLabel, Switch, Tooltip, Button } from '@mui/material'

const GeneralSettings = ({ user, bindValue }) => {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <Card sx={{ maxWidth: 345 }}>
        <CardHeader
          title='General Settings'
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tooltip arrow title='Disabled for now, sorry for the inconvenience!'>
                <TextField
                  fullWidth
                  label='Change Username'
                  type='text'
                  name='username'
                  {...bindValue('username')}
                  disabled
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Button
                // fullWidth
                variant='text'
                sx={{ color: 'coral' }}
                onClick={() => setOpen(true)}
                disabled
              >
                Change Password
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Tooltip arrow title='Will make your profile public for other users to see, they can see your stats etc.'>
                <FormControlLabel labelPlacement="start" control={<Switch checked={user.isProfilePublic} disabled {...bindValue('isProfilePublic')} />} label="Public Profile" />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip arrow title='Will enable you to see mature content, will require ID verification, coming soon!'>
                <FormControlLabel labelPlacement="start" control={<Switch checked={user.isProfilePublic} disabled {...bindValue('isProfilePublic')} />} label="Enable Mature Content" />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip arrow title='Disabled For Now'>
                <FormControlLabel labelPlacement="start" control={<Switch checked={user.isDarkMode} disabled {...bindValue('isDarkMode')} />} label="Dark Mode" />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip arrow title='Disabled For Now'>
                <FormControlLabel labelPlacement="start" control={<Switch checked={user.keepLoggedInFor30Days} disabled {...bindValue('keepLoggedInFor30Days')} />} label="Keep me logged in for 30 days" />
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ChangePasswordModal open={open} setOpen={setOpen} />
    </div>
  )
}

export default GeneralSettings