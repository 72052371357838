import { Typography, useMediaQuery } from '@mui/material'

const EasterEgg = (props) => {
  const daysNhrs = (epCt) => {
    const totalAvgMinutes = epCt * 24
    const days = Math.floor(totalAvgMinutes / 1440)
    const hours = Math.floor(totalAvgMinutes / 60)
    return `${days.toLocaleString('en-US')} days or ${hours.toLocaleString(
      'en-US'
    )} hours OR to make things even crazier, it's approximately ${totalAvgMinutes.toLocaleString('en-US')} minutes`
  }

  const m768px = useMediaQuery('(min-width:768px)')
  const m1074px = useMediaQuery('(min-width:1074px)')

  const color = 'yellowgreen'
  const margin = `${props?.sumOfAllWatchedEpisodes > 1000 && '0rem 0.4rem'}`
  const fontSize = `${props?.sumOfAllWatchedEpisodes > 1000 && m768px ? '2.2rem' : '1.5rem'}`

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ display: 'flex', flexDirection: !m1074px && 'column' }}>
        {props?.finishedShows ? (
          <Typography
            component={'span'}
            variant={!m768px ? 'body1' : 'h6'}
            sx={{ textAlign: 'center', mr: m1074px && 0.6 }}
          >
            Finished total of
            <span
              style={{
                color: color,
                margin: margin,
                fontSize: fontSize
              }}
            >
              {props?.finishedShows.toLocaleString('en-US')}
            </span>
            out of
            <span
              style={{
                color: color,
                margin: margin,
                fontSize: fontSize
              }}
            >
              {props?.length}
            </span>
            TV Shows {m1074px && `&`}
          </Typography>
        ) : null}
        <Typography component={'span'} variant={!m768px ? 'body1' : 'h6'} sx={{ mb: 2.5, textAlign: 'center' }}>
          Watched total of{' '}
          <span
            style={{
              color: color,
              margin: `${props?.sumOfAllWatchedEpisodes > 1000 && '0rem 0.2rem'}`,
              fontSize: fontSize
            }}
          >
            {props?.sumOfAllWatchedEpisodes.toLocaleString('en-US')}
          </span>{' '}
          episodes!
        </Typography>
      </div>
      {props?.sumOfAllWatchedEpisodes > 1000 ? (
        <span style={{ fontStyle: 'italic', fontSize: '0.8rem' }}>( including rewatches )</span>
      ) : null}
      <br />
      {props?.sumOfAllWatchedEpisodes > 1000 && (
        <div style={{ color: 'yellowgreen', marginBottom: '1rem', fontStyle: 'italic', textAlign: 'center' }}>
          <Typography component={'span'} variant='body1' style={{ color: 'yellowgreen' }}>
            Wow! You've watched over 1000 episodes, on average of 24mins an episode *{' '}
            {props?.sumOfAllWatchedEpisodes.toLocaleString('en-US')}; it's like{' '}
            {daysNhrs(props?.sumOfAllWatchedEpisodes)} of episodes!
          </Typography>
        </div>
      )}
      {/* {props?.sumOfAllWatchedEpisodes >= 10000 &&
        <Typography
          component={'span'}
          variant='body1'
          style={{ color: 'yellow', marginBottom: '1rem', fontStyle: 'italic', textAlign: 'center' }}
        >
          {`Okay you've hit 10k episodes, you are a GOD of watching TV Shows! `}
          <br />
          <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ&themeRefresh=1' target='_blank' rel='noreferrer'>Here's your reward!</a>
        </Typography>
      }
      {props?.sumOfAllWatchedEpisodes >= 100000 &&
        <Typography
          component={'span'}
          variant='body1'
          style={{ color: 'red', marginBottom: '1rem', fontStyle: 'italic', textAlign: 'center' }}
        >
          100k Episodes?! You are not tricking us are you? We can't rick roll you anymore, send us an email with your PayPal/Venmo/CashApp and we'll send you a dollar!
        </Typography>
      } */}
    </div>
  )
}

export default EasterEgg
